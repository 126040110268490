<template>
  <div>
    <el-empty description="当前项目未配置任何菜单，请联系管理员。"></el-empty>
  </div>
</template>

<script>
export default {
  data () {
    return {};
  },
  created () {},
  mounted () { },
  computed: {},
  props: [],
  methods: {}
};
</script>
<style lang="scss" scoped>
</style>